
module.exports.where = function (data, params, type) {
    if (Object.keys(params).length === 0) return data;
    let items = [];
    data.forEach(function (item) {
        let match = 0;
        Object.keys(params).forEach(function (key, id) {
            if (params[key] === item[key]) {
                match++;
            }
            if (id === Object.keys(params).length - 1) {
                if (type === "all") {
                    if (match === Object.keys(params).length) {
                        items.push(item);
                    }
                } else {
                    if (match > 0)
                        items.push(item);
                }
            }
        }.bind(this));
    });
    return items;
}
module.exports.whereIn = function (data) {
    return data;
}
module.exports.sort = function (data) {
    return data;
}