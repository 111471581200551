<template >
  <div align="left" style="text-align:center; position:relative;">
    <v-row justify="center">
      <v-col cols="12" style="position:relative;">
        <v-scroll-y-reverse-transition>
          <v-card
            width="300"
            style="width: 300px;
    top: -60px;
    left: 0;
    right: 0;
    padding: 10px 20px;
    border-radius: 40px;
    position: absolute;
    z-index: 2;
    margin: auto;
    transform-origin: center top 0px;"
            elevation="7"
            v-show="!hidden"
          >
            <v-row v-if="!hidden">
              <lottie
                :options="smileOptions"
                v-on:animCreated="handleAnimation"
                v-on:hitted="handleSelected"
                width="42"
                height="42"
                type="sized"
                value="smile"
              />
              <lottie
                :options="likeOptions"
                v-on:animCreated="handleAnimation"
                v-on:hitted="handleSelected"
                width="42"
                height="42"
                type="sized"
                value="like"
              />
              <lottie
                :options="loveOptions"
                v-on:animCreated="handleAnimation"
                v-on:hitted="handleSelected"
                width="42"
                height="42"
                type="sized"
                value="love"
              />
              <lottie
                :options="heartOptions"
                v-on:animCreated="handleAnimation"
                v-on:hitted="handleSelected"
                width="42"
                height="42"
                type="sized"
                value="heart"
              />
              <lottie
                :options="sadeOptions"
                v-on:animCreated="handleAnimation"
                v-on:hitted="handleSelected"
                width="42"
                height="42"
                type="sized"
                value="sade"
              />
              <lottie
                :options="angryOptions"
                v-on:animCreated="handleAnimation"
                v-on:hitted="handleSelected"
                width="42"
                height="42"
                type="sized"
                value="angry"
              />
            </v-row>
          </v-card>
        </v-scroll-y-reverse-transition>

        <div elevation="12" text @click="hidden = !hidden" class="bige3d Button">
          <img :src="iconLike" />
          RÉAGIR
          <v-badge
            v-if="reactions"
            :content="reactions.length"
            :value="reactions.length"
            color="primary"
            size="16"
            style="top:-30px; right:-20px;"
          ></v-badge>
        </div>
      </v-col>
      <v-col cols="12">
        <v-card flat style="display:inline-flex; text-align:center;" v-if="grouped">
          <div v-if="grouped.smile">
            <v-badge color="grey" :content="grouped.smile" right>
              <lottie
                :options="smileOptions"
                v-on:animCreated="handleAnimation"
                v-on:hitted="handleSelected"
                width="42"
                height="42"
                type="sized"
                value="smile"
                :loop="false"
              />
            </v-badge>
          </div>
          <div v-if="grouped.like">
            <v-badge color="grey" :content="grouped.like" right>
              <lottie
                :options="likeOptions"
                v-on:animCreated="handleAnimation"
                width="42"
                height="42"
                type="sized"
                value="like"
                :loop="false"
              />
            </v-badge>
          </div>
          <div v-if="grouped.love">
            <v-badge :content="grouped.love" :value="grouped.love" color="grey">
              <lottie
                :options="loveOptions"
                v-on:animCreated="handleAnimation"
                width="42"
                height="42"
                type="sized"
                value="love"
                :loop="false"
              />
            </v-badge>
          </div>
          <div v-if="grouped.heart">
            <v-badge :content="grouped.heart" :value="grouped.heart" color="grey">
              <lottie
                :options="heartOptions"
                v-on:animCreated="handleAnimation"
                width="42"
                height="42"
                type="sized"
                value="heart"
                :loop="false"
              />
            </v-badge>
          </div>
          <div v-if="grouped.sade">
            <v-badge :content="grouped.sade" :value="grouped.sade" color="grey">
              <lottie
                :options="sadeOptions"
                v-on:animCreated="handleAnimation"
                width="42"
                height="42"
                type="sized"
                value="sade"
                :loop="false"
              />
            </v-badge>
          </div>
          <div v-if="grouped.angry">
            <v-badge :content="grouped.angry" :value="grouped.angry" color="grey">
              <lottie
                :options="angryOptions"
                v-on:animCreated="handleAnimation"
                width="42"
                height="42"
                type="sized"
                value="angry"
                :loop="false"
              />
            </v-badge>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
const Lottie = () => import("@/components/LottieMotion.vue");
// const emojiSmile = () => import("@/assets/motions/emojis/emoji_smile.json");
import * as emojiSmile from "@/assets/motions/emojis/emoji_smile.json";
import * as emojiLike from "@/assets/motions/emojis/emoji_like.json";
import * as emojiLove from "@/assets/motions/emojis/emoji_love.json";
import * as emojiHeart from "@/assets/motions/emojis/emoji_heart.json";
import * as emojiAngry from "@/assets/motions/emojis/emoji_angry.json";
import * as emojiSade from "@/assets/motions/emojis/emoji_sade.json";
import iconLike from "@/assets/images/icons/bige-icon-like.svg";
export default {
  name: "Emotional",
  components: { lottie: Lottie },
  props: ["reactions"],
  computed: {
    grouped() {
      if (!this.reactions) return false;
      return this.reactions.reduce((a, { value }) => {
        if (!a[value]) a[value] = 1;
        else a[value] = a[value] + 1;
        // if (data) a[value].data.push(data[0]);
        return a;
      }, {});

      // return this.reactions;
    }
  },
  data: () => ({
    iconLike: iconLike,
    smileOptions: {
      animationData: emojiSmile.default,
      loop: true
    },
    likeOptions: {
      animationData: emojiLike.default,
      loop: true
    },
    loveOptions: {
      animationData: emojiLove.default,
      loop: true
    },
    heartOptions: {
      animationData: emojiHeart.default,
      loop: true
    },
    sadeOptions: {
      animationData: emojiSade.default,
      loop: true
    },
    angryOptions: {
      animationData: emojiAngry.default,
      loop: true
    },
    hidden: true,
    anim: null
  }),
  methods: {
    handleAnimation: function(anim) {
      this.anim = anim;
    },
    handleSelected: function(e) {
      // send to parent
      this.$emit("select", e);
      this.hidden = true;
    },
    stop: function() {
      this.anim.stop();
    },
    play: function() {
      this.anim.play();
    },
    pause: function() {
      this.anim.pause();
    },
    onSpeedChange: function() {
      this.anim.setSpeed(this.animationSpeed);
    }
  }
};
</script>