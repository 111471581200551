<template >
  <v-container>
    <v-row style="padding:30px;">
      <v-col
        cols="12"
        sm="4"
        v-for="(article, index) in articlesList"
        :key="article.id"
        max-width="344"
        min-width="320"
      >
        <v-hover v-slot:default="{ hover }" open-delay="200">
          <v-card
            class="item-card"
            v-ripple
            :to="'/feeds/'+article.url"
            :data-id="index"
            :elevation="hover ? 16 : 2"
          >
            <v-img
              :src="article.picture"
              :aspect-ratio="3/4"
              :style="'max-height:30vh'"
              v-if="article.picture.indexOf('http') !== -1"
            />
            <div
              link
              v-on:click="navigate('/feeds/'+article.url)"
              v-ripple
              :data-id="index"
              :ratio="3/4"
              v-if="article.picture.indexOf('http') === -1"
              :style="'width:100%; height:56.25%; max-height:30vh; overflow:hidden; background-color:#e9ebf7;'"
            >
              <Alphabet :ratio="3/4" :message="article.picture"></Alphabet>
            </div>

            <v-card-title>{{article.label}}</v-card-title>
            <v-card-text style="padding-bottom:60px;">{{article.short_description}}</v-card-text>
            <v-card-actions
              style="position:absolute; bottom:0; left:0; background:white; width:100%;"
            >
              <v-row style class="ma-3" :to="'/user/'+article.author.id">
                <v-avatar size="36">
                  <img :src="article.author.avatar" alt="John" />
                </v-avatar>
                <p class="ma-2">{{article.author.name}}</p>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import customArray from "@/helpers/custom-array";
import Alphabet from "@/components/Articles/alphabet.vue";

export default {
  name: "Articles",
  components: { Alphabet },
  props: ["articleId"],
  computed: {
    articles: {
      get: function() {
        return this.$store.state.articles;
      },
      set: function() {}
    },
    articlesList: {
      get: function() {
        let sort = this.$store.state.DB.similar("indexed", this.articleId);
        let ordered = [];
        let similar = sort.result.slice(1, 10);
        similar.forEach(sim => {
          ordered.push(
            this.$store.state.articles.filter(item => item.id == sim.label)[0]
          );
        });
        return ordered;
      },
      set: function() {}
    }
  },
  methods: {
    setArticle() {
      this.article = customArray.where(
        this.articles,
        {
          url: this.$route.params.url
        },
        "all"
      )[0];
    }
  },
  mounted() {
    this.setArticle();
  },
  watch: {
    articles() {
      return this.$store.state.articles;
    },
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.setArticle();
    }
  },
  data() {
    return {
      article: null,
      rating: 1
    };
  }
};
</script>