<template>
  <div class="article">
    <v-container class="articles">
      <!-- preview on load -->
      <v-row v-if="!article" style="position:relative;">
        <v-col cols="12" sm="4">
          <v-btn color icon v-on:click="goBack" class="backButton">
            <v-icon color="primary">{{ chevronLeft }}</v-icon>
            <span>retour</span>
          </v-btn>
          <br />
          <v-card flat style="padding:14px;" elevation="12">
            <v-img
              class="article_pix"
              src
              color="grey"
              :aspect-ratio="9/16"
              :style="'max-height:' + imageHeight"
            />
          </v-card>
        </v-col>
        <v-col col="12" sm="8" style="padding-left:4%;padding-right:4%;">
          <h1></h1>
          <p></p>

          <v-chip color="light" class="ma-1"></v-chip>
          <v-chip color="light" class="ma-1"></v-chip>
          <br />
          <br />
          <div style="margin-top:0px; text-align:left; float:left;">
            <v-card class="mx-auto" max-width="344" elevation="0" flat align="left">
              <v-list-item three-line></v-list-item>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <!-- article template -->
      <v-row v-if="article" style="position:relative;">
        <v-col cols="12" sm="4">
          <v-btn color icon v-on:click="goBack" class="backButton">
            <v-icon color="primary">{{ chevronLeft }}</v-icon>retour
          </v-btn>
          <br />
          <v-card flat style="padding:14px; left:0; right:0; margin:auto;" elevation="12">
            <v-img
              class="article_pix"
              :src="article.picture"
              :aspect-ratio="9/16"
              :style="'max-height:' + imageHeight"
              v-if="article.picture.indexOf('http') !== -1"
            />
            <Alphabet
              :message="article.picture"
              v-if="article.picture.indexOf('http') === -1"
              :ratio="16/9"
            ></Alphabet>
          </v-card>
        </v-col>
        <v-col col="12" sm="8" style="padding-left:4%;padding-right:4%;">
          <h1 style="padding-top:40px; padding-bottom:20px">{{article.label}}</h1>
          <p class="bige-info" v-if="article.intro" v-html="parseHTML(article.intro)"></p>
          <p class="bige-info" v-if="!article.intro">{{article.short_description}}</p>

          <v-chip color="light" class="ma-1">{{article.category}}</v-chip>
          <v-chip color="light" class="ma-1" v-for="tag in article.tags" :key="tag">{{tag}}</v-chip>
          <br />
          <br />
          <div
            v-if="article && article.author"
            style="margin-top:0px; text-align:left; float:left;"
          >
            <v-card
              class="mx-auto"
              max-width="344"
              elevation="0"
              flat
              :to="'/user/'+article.author.id"
              align="left"
            >
              <v-list-item three-line>
                <v-avatar size="68" color="light" style="margin-right:20px;">
                  <img :src="article.author.avatar" :alt="article.author.name" />
                </v-avatar>
                <v-list-item-content>
                  <div class="overline mb-4"></div>
                  <v-list-item-title class="headline mb-1">{{article.author.name}}</v-list-item-title>
                  <v-list-item-subtitle>{{article.publication}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div v-if="article">
      <div v-if="article.markdown && article.markdown !== ''">
        <markDownContainer v-if="article" :input="article.markdown"></markDownContainer>
      </div>
    </div>
    <div v-if="article">
      <emojis v-on:select="selectEmoji" :reactions="article.react"></emojis>
    </div>
    <v-container class="articles" id="more_articles">
      <v-tabs flat ref="tab" id="tabOffset">
        <v-tab to="#comments">Commentaires</v-tab>
        <v-tab to="#similar">Similaires</v-tab>
        <!-- <v-tab to="#infos">Infos</v-tab> -->
      </v-tabs>
    </v-container>
    <div class="fixedScroll" v-if="fixedTab">
      <v-container class="articles" style>
        <v-tabs flat>
          <v-tab to="#comments">Commentaires</v-tab>
          <v-tab to="#similar">Similaires</v-tab>
          <!-- <v-tab to="#infos">Infos</v-tab> -->
        </v-tabs>
      </v-container>
    </div>
    <div class="container_light">
      <bigeComments v-if="tab == 0" id="comments" :article="article" v-on:comment="saveComment"></bigeComments>
      <bigeSimilar v-if="tab == 1" id="similar" :articleId="article.id"></bigeSimilar>
      <v-container v-if="tab == 2">
        <h2>Infos</h2>
      </v-container>
    </div>
    <div class="container_light"></div>

    <v-btn
      v-if="article && $store.state.userProfile && $store.state.userProfile.scopes.includes('writter')"
      class="ma-2"
      large
      fab
      bottom
      right
      fixed
      color="primary"
      :to="`/write/${article.id}`"
    >
      <v-icon>{{pencil}}</v-icon>
    </v-btn>
  </div>
</template>

<script>
// @ is an alias to /src
import logoBlue from "@/assets/images/logo-blue.svg";
// eslint-disable-next-line no-unused-vars
import customArray from "@/helpers/custom-array";
import bigeComments from "@/components/bigeComments";
import bigeSimilar from "@/components/bigeSimilar";
import markDownContainer from "@/components/markDownContainer.vue";
import emojis from "@/components/bigeEmoji";
//import customArray from "@/helpers/custom-array";
// eslint-disable-next-line no-unused-vars
import { mdiChevronLeft, mdiPencil } from "@mdi/js";
import Alphabet from "@/components/Articles/alphabet.vue";

import Axios from "axios";
const config = require("../credentials/ENV/prod");
var baseURL = config.API.base_url;
if (process.env.NODE_ENV === "development") {
  baseURL = config.API.base_url_locale;
}
const API = Axios.create({
  baseURL: baseURL, // SO :  swicth to '../credentials/ENV/prod' for local debug
  timeout: 10000,
  headers: {
    "Accept-Language": "fr-FR" // TODO switch User accept langages ESP belgium NL FR ...
  }
});

export default {
  name: "Articles",
  components: {
    bigeComments,
    bigeSimilar,
    markDownContainer,
    emojis,
    Alphabet
  },
  data() {
    return {
      logoBlue: logoBlue,
      article: null,
      rating: 1,
      tab: 0,
      fixedTab: false,
      chevronLeft: mdiChevronLeft,
      pencil: mdiPencil
    };
  },
  computed: {
    imageHeight: {
      get: function() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return "50vh";
          case "sm":
            return "60vh";
          case "md":
            return "70vh";
          case "lg":
            return "80vh";
          case "xl":
            return "700px";
          default:
            return "800px";
        }
      }
    },
    articles: {
      get: function() {
        // this.setArticle();
        return this.$store.state.articles;
      },
      set: function() {}
    },
    articlesList: {
      get: function() {
        return this.$store.state.articles;
      },
      set: function() {}
    },
    offsetTop() {
      return this.$store.state.offsetTop;
    }
  },
  methods: {
    async setArticle() {
      this.$store.state.isLoading = true;
      let articles = await API.get(`/articles/url/` + this.$route.params.url, {
        params: { url: this.$route.params.url }
      });
      this.article = articles.data.data[0];
      this.$store.commit("setProp", {
        prop: "currentArticle",
        value: this.article
      });
      this.$store.state.isLoading = false;
    },
    parseHTML(str) {
      return str.replace(/\n/g, "<br/>");
    },
    goBack() {
      if (this.$store.state.history.length > 1) {
        let goTo = -1;
        for (let i = this.$store.state.history.length - 1; i > 0; i--) {
          if (window.location.pathname !== this.$store.state.history[i].path) {
            this.$router.go(goTo);
            break;
          }
          goTo--;
        }
      } else {
        this.$router.push({ path: "/feeds" });
      }
    },
    saveComment(e) {
      this.$store.commit("comment", { id: this.article.id, value: e });
    },
    selectEmoji(e) {
      if (!this.$store.state.currentUser) {
        this.$store.commit("setProp", {
          prop: "loginDialog",
          value: true
        });
      } else {
        this.$store.commit("react", { id: this.article.id, value: e });
      }
      setTimeout(
        function() {
          this.setArticle();
        }.bind(this),
        400
      );
    },
    checkLocal() {
      if (
        this.$store.state.articles.filter(
          art => art.url === this.$route.path.replace("/feeds/", "")
        ).length > 0
      ) {
        this.article = this.$store.state.articles.filter(
          art => art.url === this.$route.path.replace("/feeds/", "")
        )[0];
        this.$store.commit("setProp", {
          prop: "currentArticle",
          value: this.article
        });
      }
    }
  },
  created() {
    this.checkLocal();
    this.setArticle();
  },
  async mounted() {},
  watch: {
    articles() {
      this.setArticle();
      // return this.$store.state.articles;
    },
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      if (typeof to.hash !== "undefined") {
        switch (to.hash) {
          case "#comments":
            this.tab = 0;
            break;
          case "#similar":
            this.tab = 1;
            break;
          case "#more":
            this.tab = 2;
            break;
          case "#infos":
            this.tab = 3;
            break;
        }
        // this.next();
      }
      this.checkLocal();

      this.setArticle();
    },
    offsetTop() {
      /* if (
        document.getElementById("tabOffset").offsetTop - 15 >=
        this.$store.state.offsetTop
      ) {
        this.fixedTab = false;
      } else {
        this.fixedTab = true;
      } */
    }
  }
};
</script>
