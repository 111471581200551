<template>
  <v-col cols="12" class="ma-0 pa-0">
    <v-card elevation="0">
      <v-card-title class>
        <v-avatar color="grey">
          <v-img :src="comment.avatar" />
        </v-avatar>
        <span class="headline" style="padding-left:15px;">{{comment.name}}</span>

        <v-spacer></v-spacer>

        <v-menu bottom left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-on:click="respond">
              <v-list-item-title>Répondre</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="!edition && $store.state.userProfile && $store.state.userProfile.id === comment.userID"
              v-on:click="edition = !edition"
            >
              <v-list-item-title>Éditer</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-on:click="edition = !edition"
              v-if="edition && $store.state.userProfile && $store.state.userProfile.id === comment.userID"
            >
              <v-list-item-title>Annuler l'édition</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-on:click="deleteComment"
              v-if="$store.state.userProfile && $store.state.userProfile.id === comment.userID"
            >
              <v-list-item-title>Supprimer</v-list-item-title>
            </v-list-item>
            <v-list-item v-on:click="mp">
              <v-list-item-title>Écrire un message privé</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>

      <v-card-text v-if="!edition">{{comment.value}}</v-card-text>
      <div v-if="edition" class="ma-5">
        <v-textarea
          auto-grow
          name="input-7-1"
          label="Éditer ton commentaire"
          :value="comment.value"
          v-model="comment.value"
        ></v-textarea>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text v-on:click="edition = false">Annuler</v-btn>
          <v-btn color="primary" text v-on:click="saveEdition">Enregistrer</v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-col>
</template>
<script>
export default {
  name: "Comments",
  props: ["comment", "articleID"],
  data: () => ({
    edition: false
  }),
  methods: {
    mp: function() {
      if (!this.checklogged()) {
        return false;
      }
    },
    edit: function() {
      if (!this.checklogged()) {
        return false;
      }
      this.edition = true;
    },
    saveEdition: function() {
      this.comment.articleID = this.articleID;
      this.comment.id = this.comment.commentID;
      this.$store.commit("commentEdit", this.comment);
      this.edition = false;
    },
    deleteComment: function() {
      if (!this.checklogged()) {
        return false;
      }
      this.comment.articleID = this.articleID;
      this.comment.id = this.comment.commentID;
      this.$store.commit("commentDelete", this.comment);
    },
    respond: function() {
      if (!this.checklogged()) {
        return false;
      }
    },
    checklogged: function() {
      if (!this.$store.state.currentUser) {
        this.$store.commit("setProp", {
          prop: "loginDialog",
          value: true
        });
        return false;
      } else {
        // this.$store.commit("react", { id: this.article.id, value: e });
        return true;
      }
    }
  }
};
</script>