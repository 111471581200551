<template >
  <v-container>
    <v-row align="center" dense>
      <v-col col="12" sm="7" align="center" dense>
        <v-card flat class="mx-auto" color>
          <br />
          <v-list-item three-line>
            <br />
            <v-list-item-content>
              <br />
              <v-textarea
                style="padding:15px;"
                name="input-7-1"
                label="Entre ton commentaire ici :"
                :counter="128"
                value
                hint="Entrez un texte ou copiez un lien"
                height="100"
                v-model="userComment"
                v-on:focus="focusComment"
              ></v-textarea>
              <v-btn color="primary" v-on:click="saveComment">Envoyer</v-btn>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <br />
    <v-row dense v-if="article">
      <!-- <v-col>{{comment.value}}, {{comment.name}}, {{comment.avatar}}</v-col>
      <v-col
        class="comment"
        col="12"
        sm="7"
        dense
        v-for="(comment, index) in article.comments"
        :key="index"
      >
        <v-card flat class="mx-auto">
          <v-list-item>
            <v-list-item-avatar color="grey">
              <v-img :src="comment.avatar" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="headline">{{comment.name}}</v-list-item-title>
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>
            <div style="position:absolute; left:10px; top:10px;">
              <v-btn icon v-on:click="commmentOn != commmentOn;">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>

              <v-list v-if="commmentOn">
                <v-list-item>
                  <v-list-item-title>Item #1</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>Item #2</v-list-item-title>
                </v-list-item>
              </v-list>
            </div>
          </v-list-item>

          <v-card-text>{{comment.value}}</v-card-text>
        </v-card>
      </v-col>-->
      <v-col
        class="comment"
        col="12"
        sm="7"
        dense
        v-for="(comment, index) in article.comments"
        :key="index"
      >
        <Comment :comment="comment" :articleID="article.id"></Comment>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
// @ is an alias to /src
// import customArray from "@/helpers/custom-array";
import Comment from "@/components/Comments/Comments.vue";

export default {
  name: "ArticlesComments",
  components: { Comment },
  props: ["article"],
  computed: {
    articles: {
      get: function () {
        return this.$store.state.articles;
      },
      set: function () {},
    },
    articlesList: {
      get: function () {
        return this.$store.state.articles;
        /* customArray.where(
          this.$store.state.articles,
          this.$route.params,
          "all"
        ); */
      },
      set: function () {},
    },
  },
  methods: {
    focusComment() {
      if (!this.$store.state.currentUser) {
        this.$store.commit("setProp", {
          prop: "loginDialog",
          value: true,
        });
      } else {
        // this.$store.commit("react", { id: this.article.id, value: e });
      }
    },
    setArticle() {
      /* this.article = customArray.where(
        this.articles,
        {
          url: this.$route.params.url
        },
        "all"
      )[0]; */
    },
    saveComment() {
      this.$emit("comment", this.userComment);
    },
  },
  mounted() {
    this.setArticle();
  },
  watch: {
    articles() {
      return this.$store.state.articles;
    },
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.setArticle();
    },
  },
  data() {
    return {
      rating: 1,
      userComment: "",
      commmentOn: false,
    };
  },
};
</script>