<template >
  <div>
    <v-row style="padding-bottom: 63px; ">
      <v-col cols="12">
        <div v-html="compiledMarkdown" class="MarkDownPreview"></div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import marked from "marked";
// import _ from "lodash";

import hljs from "highlight.js/lib/highlight";
import javascript from "highlight.js/lib/languages/javascript";
import "@/assets/sass/hljs-custom.scss";

hljs.registerLanguage("javascript", javascript);

export default {
  name: "MarkDownContainer",
  components: {},
  props: {
    input: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {};
  },
  computed: {
    compiledMarkdown: function() {
      return marked(this.input, {});
    }
  },
  watch: {
    $route() {
      this.setHighLight();
    }
  },
  mounted() {
    this.setHighLight();
  },
  methods: {
    setHighLight() {
      setTimeout(function() {
        document.querySelectorAll("pre code").forEach(block => {
          // block.classList.add("javascript");
          hljs.highlightBlock(block);
        });
      }, 100);
    }
    /* update: _.debounce(function(value) {
      // this.input = value;
      this.$emit("updateInput", value);

      setTimeout(function() {
        document.querySelectorAll("pre code").forEach(block => {
          // block.classList.add("javascript");
          hljs.highlightBlock(block);
        });
      }, 100);
    }, 10) */
  }
};
</script>